import { Option } from '../../types/common/option';

const brokerTypes: Option[] = [
    {
        label: "CBRE",
        value: "cbre",
        order: 1
    },
    {
        label: "Conjunctional",
        value: "conjunctional",
        order: 2
    }
];

export default brokerTypes;