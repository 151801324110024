import React, { FC } from "react";
import styled from 'styled-components';
import FormSelect, { FormSelectProps } from '../../../../components/form-select/form-select';
import { Option } from '../../../../types/common/option';
import FormInput, { FormInputProps } from '../../../../components/form-input/form-input'
import { Col, Row } from 'react-styled-flexboxgrid'
import DeleteIcon from '../../../../assets/images/png/deleteIcon.png';
import DragIcon from '../../../../assets/images/png/dragIcon.png';
import { convertValidationJSON } from '../../../../utils/forms/validation-adapter';
import GLField from '../../../../components/form/gl-field';
import GLForm from '../../../../components/form/gl-form';
import { generateKey } from "../../../../utils/keys";

export interface MultiDropdownProps extends Option {
    name: string,
    categoryOptions: Option[],
    interval: number,
    currencies: Option[],
    baseData: any,
    deleteRow: any,
    index: number,
    prefix?: string,
    changeHandler?: (data: any, index: number) => void
}

const MultiDropdown: FC<MultiDropdownProps> = (props) => {

    const { categoryOptions, interval, deleteRow, index, prefix, value, name, changeHandler, currencies, baseData } = props;

    const validations = {};

    const collectData = (data: any) => {
        if (changeHandler) {
            changeHandler(data, index)
        }
    }

    const isValue1Disabled = baseData.interval1 === null ? true : false;
    const isValue2Disabled = baseData.interval2 === null ? true : false;
    const isValue3Disabled = baseData.interval3 === null ? true : false; 

    return (
        <GLForm initVals={value}
            validationAdapter={convertValidationJSON}
            validationJSON={validations}
            changeHandler={collectData}
            key={generateKey()}>
            <MultiDropdownContainer data-testid="demographics-row">
                <Row key={generateKey()}>
                    <Col xs={3}>
                        <GLField<FormSelectProps> key={'categoryType-' + index} use={FormSelect} isClearable={false} defaultValue={value.category} options={categoryOptions} disabled={false} name="category"  label={(index === 0) ? 'Category' : ''} />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key={'value1-' + index} defaultValue={2} index={index} appendToId={name} numericOnly={true} acceptDecimals={true} use={FormInput} name="value1" 
                            disabled={isValue1Disabled} label={(index === 0) ? 'Values' : undefined} suppressFlexDisplay={true} />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key={'value2-' + index} defaultValue={3} index={index} appendToId={name} numericOnly={true} acceptDecimals={true} use={FormInput} name="value2"
                            disabled={isValue2Disabled} label={(index === 0) ? '\u00A0' : undefined} suppressFlexDisplay={true} />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key={'value3-' + index} defaultValue={5} index={index} appendToId={name} numericOnly={true} acceptDecimals={true} use={FormInput} name="value3"
                            disabled={isValue3Disabled} label={(index === 0) ? '\u00A0' : undefined} suppressFlexDisplay={true} />
                    </Col>
                    <img style={(index === 0) ? { top: '58px' } : { top: '32px' }} src={DeleteIcon} data-testid="deleteDropdownRow" onClick={() => deleteRow(index)} />
                    <img style={(index === 0) ? { top: '64px', right: '-60px', height: '22px' } : { top: '38px', right: '-58px', height: '22px' }} src={DragIcon} data-testid="drag" />
                </Row>
            </MultiDropdownContainer>
        </GLForm>
    )
}

export default MultiDropdown;

const MultiDropdownContainer = styled.div`
    margin: 20px 0;
    > div > img {
        position:absolute;
        cursor:pointer;
        height: 32px;
        width: auto;
        right: -31px;
    }
    > div {
        position:relative;
        margin-top:-5px;
    }
`;